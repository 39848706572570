*{
  -webkit-box-sizing: border-box;
  box-sizing: border-box; 
}

a{
  color: #1967d2;
}

a:hover {
  color: #e53900;
}

body{
  max-width: 100%;
  font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  line-height: 26px;
  font-size: 15px;
  color: #2b2b2b;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

div#root{
  max-width: 100%;
  margin: 0 auto;
}

.main-wrapper{
  max-width: 100%;
  margin:0 auto;
  display: block;
}

.flags{
  max-width: 100%;
  margin:0 auto;
}

.share-box{
  max-width: 1200px;
  margin:0 auto;
}
.share-button{
  position: relative;
  margin: 0 auto;
  width: 264px;
  padding: 14px 12px 6px 12px;
}
.share-caption{
  color: #4b4b4b;
  font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
  position: absolute;
  font-weight: bold;
  top: 0;
  left: 0;
  font-size: 1em;
  padding: 0 1em;
  margin: 0;
  background-color: white;
  transform: translateY(-50%) translateX(5.8em);
}

.react-share__ShareButton{
  margin: 0 8px;
}

/*.react-share__ShareButton svg circle{
  fill: #505050;
}*/

/*topics*/
.topics-box{
  width: 84%;
  margin:0 auto;
}
.topics-button{
  position: relative;
  border: 2px solid #a5d6a7;
  border-radius: 4px;
  margin: 0 auto;
  width: 100%;
  padding: 14px 12px 6px 12px;
}
.topics-caption{
  font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
  position: absolute;
  font-weight: bold;
  top: 0;
  left: 0;
  font-size: 1em;
  padding: 0 1em;
  margin: 0;
  background-color: white;
  transform: translateY(-50%) translateX(1.0em);
}
.topic-flag-image{
  width: 50%;
  margin: 0 auto;
}
.topic-flag-image img{
  width: 100%;
  max-height: 300px;
  border: 2px solid #ccc;;
}

/*tipsエリア*/
.tips-list{
  width: 84%;
  margin:0 auto;
}



/*
Headerエリア
*/

header{
  width: 100%;
  height: 86px;
  padding: 8px 12px;
}

.header-inner{
  max-width: 1200px;
  margin: 0 auto;
}

.header-inner h1{
  margin:0;
  padding:0;
}

header nav{
  float: left;
  line-height: 74px;
  padding: 0 12px;
}

header nav a{
  padding: 0 12px;
  text-decoration: none;
  color: #2b2b2b;
  font-size: 0.9em;
  cursor: pointer;
}

.logoHeader{
  width: 120px;
  height: 68px;
  float: left;
}

/*
国旗表示エリア
*/

.howto{
  display: none;
  max-width: 500px;
  margin: 42px auto;
  background-color: #d4edda;
  border-top: 12px solid #519657;
  padding: 11px 22px;
  border-radius: 4px;
}
.howto h3{
  cursor: pointer;
}
.howto ol, .howto ul{
  padding-left: 20px;
}


/*エリアとタグ囲んでるやつ*/
.attributeBox{
  margin-top: 34px;
}

/*エリアを囲んでるやつ*/
.areaBox{
  max-width: 100%;
  margin-bottom: 2px;
  line-height: 20px;
}

/*エリア名*/
.areaName{
  display: inline-block;
  background: #81c784;
  padding: 2px 8px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
  color:#fff;
}

/*エリアを囲んでるやつ*/
.unitedBox{
  max-width: 100%;
  margin-bottom: 2px;
  line-height: 20px;
}

/*エリア名*/
/*国連*/
.kokuren{
  display: inline-block;
  background: #e57373;
  padding: 2px 8px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
  color:#fff;
}

/*国連じゃない*/
.not-kokuren{
  display: inline-block;
  background: #64b5f6;
  padding: 2px 8px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
  color:#fff;
}

/*海外領土・自治領*/
.other{
  display: inline-block;
  background: #ef9246;
  padding: 2px 8px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
  color:#fff;
}

/*地方旗*/
.local{
  display: inline-block;
  background: #4464f3;
  padding: 2px 8px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
  color:#fff;
}

/*地方旗*/
.old{
  display: inline-block;
  background: #7b7b7b;
  padding: 2px 8px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
  color:#fff;
}

/*
タグを囲んでるやつ
.tagbox{
  max-width: 100%
}

タグ名
.tag{
  display: inline-block;
  background: #64b5f6;
  padding: 2px 8px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 6px;
  color:#fff;
}
*?

/*国旗表示エリア全体を囲んでるやつ*/
.flag-bg{
  background: #f1f8e9;
  padding-bottom: 68px;
  padding-top: 68px;
}
.flags .flag-bg{
  background: #f1f8e9;
  padding-bottom: 26px;
  padding-top: 26px;
}
.flags .share-caption{
  display: none;
}
.flags .share-button{
  border: 0;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 0 6px #ccc;
}

.flagArea{
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

/*国旗画像や国名タグをかこんでるやつ*/
.flagBox{
  width: 19%;
  display: inline-block;
  /*border: 1px solid #ccc;
  border-radius: 4px;*/
  padding: 18px;
  height: 250px;
  vertical-align: middle;
  margin: 20px 3%;
  cursor: pointer;
  box-shadow: 0 0 7px #ccc;
  background: #fff;
  overflow: hidden;
}
.flagBox:hover{
  box-shadow: 0 0 6px #9b9b9b;
  transition-duration: 0.3s;
  transform: translateY(-2px);
}

/*画像を囲んでるやつ*/
.flagImageBox{
  width: 100px;
  height: 70px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 12px;
  z-index: 1;
}

/*画像自体*/
.countryFlag{
  border: 1px solid #f0f0f0;
  max-width: 100px;
  max-height: 70px;
  position: absolute;
  bottom: 0;
  z-index: -1;
}

/*国名*/
.countryName{
  text-align: center;
  color: #4b4b4b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:nowrap;
}


/*
特定の国旗にだけ適用したいCSS
*/

.nepal{
  border: 0;
  left:21px;
}

.switzerland, .vatican{
  left:15px;
}

.niger{
  left:9px;
}

.belgium{
  left:9.5px;
}

.monaco{
  left:5px;
}

.rhode-island{
  left:11px;
}

.ohio{
  border: none;
}
.ohioDate{
  border: none;
}


/*なぜか下記２つだけborderとの間に隙間ができるので、max-heightを100pxにしておく*/
.norway, .denmark{
  max-height: 100px;
}


/*
検索用のチェックボックスのCSS
*/


/*検索用エリア全体を囲んでるやつ*/
.searchBox{
  max-width: 1200px;
  margin: 0 auto;
  /*border: 2px solid #b57a4e;
  border-radius: 4px;*/
  padding: 24px;
  font-size: 0.94em;
  margin-top: 36px;
  margin-bottom: 12px;
}

h3.detail-search span, h4.search-mode span{
  cursor: pointer;
}
h3.detail-search{
  margin-top: 2em;
}

/* インデント的なやつ */
.mBox{
  margin-left: 0;
}

/* 三角の位置調整 */
.svg-inline--fa.fa-w-10{
  margin-left: 6px;
}

/* 緑の説明書のところでは邪魔なので */
.howto p .svg-inline--fa.fa-w-10{
  margin: 0;
}

/*ラベル名とチェックボックスを囲んでるやつ*/
.button{
  display: inline-block;
  cursor: pointer;
  margin: 10px 8px 0 0;
  padding: 6px 10px;
  color: #4b4b4b;
}

/*ラベル名に使ってるやつ
.searchButton{
  cursor: pointer;
  margin: 4px 12px 4px 38px;
  line-height: 32px;
}
*/

/*チェックボックスのチェック入ってない時*/
.checkbox0{
  display:block;
  position:absolute;
  width:30px;
  height:30px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  box-sizing: border-box;
}

/*チェックボックスにチェックが入った時*/
.checkbox1{
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  box-sizing: border-box;
}

.checkbox1:after{
  content: "";
  display:block;
  position:absolute;
  top: 8px;
  left: 8px;
  width:14px;
  height:14px;
  background: #a5d6a7;
  border-radius: 2px;
}

/*チェックボックスのチェック入ってない時*/
.checkboxOFF{
  display:block;
  display:none;
  position:absolute;
  width:30px;
  height:30px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  box-sizing: border-box;
}

/*チェックボックスにチェックが入った時*/
.checkboxON{
  content: "";
  display: block;
  display:none;
  position: absolute;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  box-sizing: border-box;
}

.checkboxON:after{
  content: "";
  display:block;
  display:none;
  position:absolute;
  top: 8px;
  left: 8px;
  width:14px;
  height:14px;
  background: #a5d6a7;
  border-radius: 2px;
}

.search-bg{
  background: #fff;
}

/*serachCheckBoxの略ね*/
.scb{
  margin-bottom: 8px;
}

.scb .buttonOFF{
  border: 1px solid #c8e6c9;
  border-radius: 12px;
  box-shadow: 0 0 6px #fbfffc;
}

.scb .buttonOFF:hover{
  transition-duration: 0.2s;
  box-shadow: 0 0 6px #c8e6c9;
}

.scb .buttonON{
  border: 1px solid #c8e6c9;
  border-radius: 12px;
  box-shadow: none;
  background: #c8e6c9;
  transition-duration: 0.2s;
}

.inputgroup{
  margin-bottom: 36px;
}

.keywordSearch{
  position: relative;
}

form svg.fa-search{
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 23px;
  color: #ccc;
}

.formInput{
  width: 100%;
  border: 1px solid #c8e6c9;
  padding: 0 1em;
  line-height: 48px;
  font-size: 18px;
  border-radius: 2.5em;
}

.formInput::placeholder{
  color:#ccc;
  font-weight: normal;
}

.formInput:focus{
  outline: 0;
  border-color: #a5d6a7;
}

.select-hiragana{
  display: inline-block;
}
.select-hiragana select{
  color: #4b4b4b;
  background: #fff;
  cursor: pointer;
  padding: 12px 8px;
  border: 1px solid #c8e6c9;
  border-radius: 12px;
}
.select-hiragana select:focus{
  outline: 0;
  border-color: #a5d6a7;
}
.select-hiragana select option{
  color: #4b4b4b;
}

/*表示件数*/
.result{
  margin-top: 36px;
  color: #4b4b4b;
}

/*
modalのcss
*/

/*modalの一番上*/
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: auto;
  z-index: 3;
  overflow-y: scroll;
}

/*modalの白いとこ、画像とかの表示部分*/
/*【お得情報】position:absolute に right:0; left:0; を指定して、margin:auto にすると中央揃えできる。right,leftがないと出来ない*/
.modal-inner{
  position: absolute;
  width: 60%;
  max-width: 1000px;
  margin: auto;
  top: 10%;
  right: 0;
  left: 0;
  background: #fff;
  border-radius: 8px;
  padding: 0 10px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  z-index: 7;
}
.modal-inner::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display:none;
}

/*2カラムにする用*/
.modal-column{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0;
  padding-bottom: 18px;
}

/*国旗表示エリア*/
.modal-flag{
  width: 50%;
}

/*国旗画像自体*/
.modal-flag-image{
  display: block;
  max-width: 80%;
  max-height: 300px;
  margin: 0 6.5% 0 13.5%;
  border: 1px solid #f0f0f0;
}

.nepalModal{
  border: 0;
}

/*地図部*/
.modal-map-image{
  display: block;
  max-width: 80%;
  max-height: 300px;
  margin: 0 13.5% 0 6.5%;
}
.modal-location{
  width: 50%;
}

/*情報部*/
.modal-info{
  width: 100%;
}
.modal-intro{
  margin: 0 8%;
}

/*国名*/
.modal-name{
  text-align: center;
}
.modal-name h2{
  font-weight: normal;
  margin-bottom: 0.2em;
}
.modal-name p{
  margin-top: 0;
}

/*ボタン*/

.modal-button{
  display: flex;
  justify-content: space-between;
  position: relative;
}

.modal-button button{
  cursor: pointer;
  width: 50%;
  margin: 32px 8%;
  height: 48px;
  padding: 0;
  font-size: 0.84em;
}

.modal-button button a{
  color: #2b2b2b;
}

.modal-button button:focus{
  outline: none;
}

/*borderとbackgroundで色変えると情報量増えてダサい*/
/*詳細と閉じるボタンのCSS*/
.detail{
  background: #c8e6c9;
  color: #2b2b2b;
  font-size: 16px;
  line-height: 48px;
  border: 0;
}
.detail a{
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
}
.detail a:active{
  color: inherit;
}
.detail a:visited{
  color: inherit;
}
.fa-external-link-alt {
  line-height: 48px;
  margin-left: 8px;
  width: 0.9em;
  color: #3b3b3b;
}

.close{
  background: #e5e5e5;
  color: #2b2b2b;
  font-size: 16px;
  line-height: 48px;
  border: 0;
  border-radius: 2.5rem;
}

/*ネパール用*/

.nepalBox .modal-flag-image{
  margin: 0 auto;
}


/*
Footer
*/

footer{
  width: 100%;
}

footer .footer-list{
  float: left;
  margin-left: 32px;
  margin-bottom: 32px;
  margin-top: 22px;
}

.logoFooter{
  width: 120px;
  height: 68px;
  float: left;
  margin-left: 3%;
  margin-top: 16px;
}

.footer-inner{
  max-width: 1200px;
  margin: 0 auto;
  padding: 8px 24px;

}

footer ul{
  margin-left: 0;
  padding-left: 0;
}

footer ul li{
  list-style-type: none;
  margin-bottom: 6px;
}

footer ul li a{
  color: #6b6b6b;
  font-weight: bold;
  font-size: 0.90em;
  cursor: pointer;
  text-decoration: none;
}

footer h4{
  margin-top: 0;
}

/*旗個別のページ*/

.flag-page{
  max-width: 900px;
  margin: 0 auto;
  padding: 48px;
  background: #fff;
  border-radius: 4px;
}

/*関連する旗*/



.relation h2 {
  font-size: 1.2em;
  color: #4b4b4b;
  border-bottom: 1px solid #a5d6a7;
  padding-bottom: 8px;
  padding-left: 12px;
}
.relation h3 {
  display: inline-block;
  border-bottom: 1px solid #a5d6a7;
  font-size: 1.06em;
  color: #4b4b4b;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.relation-flag{
  display: block;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 18px 4px;
}
.relation-flag:hover{
  transition-duration: 0.3s;
  box-shadow: 0 0 6px #e5e5e5;
  transform: translateY(-2px);
}
/*画像を囲んでるやつ*/
.relation-flag-box{
  width: 90px;
  height: 63px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 12px;
  z-index: 1;
}

.relation-flag-img{
  border: 1px solid #f0f0f0;
  max-width: 90px;
  max-height: 63px;
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.relation-flag h4{
  text-align: center;
  margin: 0;
  color: #4b4b4b;
  font-weight: normal;
  font-size: 0.92em;
}

.grid-relation{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 1.4rem;
  align-items: end;
  margin-bottom: 3em;
}
.grid-relation-margin{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 1.4rem;
  margin-bottom: 1em;
}
.grid-relation a{
  text-decoration: none;
}
.grid-relation-margin a{
  text-decoration: none;
}

.nepalreFlag{
  border: 0;
}

.nepalreFlag{
  border: 0;
  left:21px;
}

.switzerlandreFlag, .vaticanreFlag{
  left:15px;
}

.nigerreFlag{
  left:9px;
}

.belgiumreFlag{
  left:9.5px;
}

.monacoreFlag{
  left:5px;
}


/*なぜか下記２つだけborderとの間に隙間ができるので、max-heightを100pxにしておく*/
.norwayreFlag, .denmarkreFlag, .icelandreFlag{
  max-height: 100px;
}

/*旗の個別ページ用*/

/*2カラムにする用*/
.flag-date-column{
  width: 84%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
  padding-bottom: 18px;
}

/*国旗表示エリア*/
.flag-date-image{
  width: 50%;
  margin:0 auto;
}

/*国旗画像自体*/
.flag-date-image-svg{
  display: block;
  max-width: 100%;
  max-height: 300px;
  margin: 0;
  border: 1px solid #f0f0f0;
}

.nepalDate{
  border: 0;
}

/*地図部*/
.flag-date-map-image{
  display: block;
  max-width: 100%;
  max-height: 300px;
  margin: 0;
}
.flag-date-location{
  width: 50%;
}

/*情報部*/
.flag-date-info{
  width: 100%;
}
.flag-date-intro{
  margin: 0 8%;
  margin-bottom: 4.0em;
}
.flag-date-desc{
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}

/*国名*/
.flag-date-name{
  text-align: center;
  margin: 0 8% 42px 8%;
  padding: 0 14px;
}
.flag-date-name h2{
  font-weight: normal;
  margin-bottom: 0.2em;
}
.flag-date-name p{
  margin-top: 0;
  font-size: 0.9em;
}

/*ボタン*/

.flag-date-button{
  display: flex;
  justify-content: space-between;
  position: relative;
}

.flag-date-button button.detail{
  cursor: pointer;
  width: 50%;
  margin: 32px 8%;
  height: 48px;
  padding: 0;
  font-size: 0.84em;
  border-radius: 2.5rem;
}

.flag-date-button button a{
  color: #2b2b2b;
}

.flag-date-button button:focus{
  outline: none;
}

/*borderとbackgroundで色変えると情報量増えてダサい*/
/*詳細と閉じるボタンのCSS*/
.detail{
  color: #2b2b2b;
  font-size: 16px;
  line-height: 48px;
  border: 0;
}
.detail a{
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
}
.detail a:active{
  color: inherit;
}
.detail a:visited{
  color: inherit;
}
.fa-external-link-alt {
  line-height: 48px;
  margin-left: 8px;
  width: 0.9em;
  color: #3b3b3b;
}

.close{
  background: #e0e0e0;
  color: #2b2b2b;
  font-size: 16px;
  line-height: 48px;
  border: 0;
}

.close-link{
  cursor: pointer;
  width: 50%;
  margin: 32px 8%;
  height: 48px;
  padding: 0;
}

.close-link button{
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0.84em;
}

/*ネパール用*/

.nepal-flag-date .flag-date-image{
  margin: 0 auto;
}

.flag-date{
  margin-bottom: 32px;
}

.saintChristopherNevis-flag-date .detail{
  font-size: 0.8em;
}



















/*-------------------------*/
/*ここからデバイス別のCSS*/


@media screen and (max-width: 1024px){
  .flagBox{
    width: 27%;
    display: inline-block;
    padding: 18px;
    height: 250px;
    vertical-align: middle;
    margin: 20px 3%;
    cursor: pointer;
  }

    /*modal*/

    .modal-inner{
      top: 5%;
      width: 70%;
      max-height: 90vh;
    }
    .modal-column{
      display: block;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0;
    }
  
    /*国旗表示エリア*/
    .modal-flag{
      width: 100%;
      padding-bottom: 18px;
    }

    .modal-location{
      width: 100%;
    }
    .modal-map-image{
      display: block;
      max-width: 80%;
      max-height: 200px;
      margin: 0 auto;
    }
  
    /*国旗画像自体*/
    .modal-flag-image{
      display: block;
      max-width: 80%;
      max-height: 230px;
      margin: 0 auto;
    }

    .modal-info{
      width: 100%;
    }
    .modal-intro{
      margin: 0 5%;
    }
  
    .modal-button{
      display: block;
    }
    .modal-button button{
      display: block;
      width: 90%;
      margin: 24px auto;
      padding: 0 6px;
    }

    .logoFooter{
      margin-left: 3%;
    }
}
@media screen and (max-width: 800px){

  .flag-page{
    border-radius: 0;
  }

  .relation-flag{
    cursor: pointer;
  }
  .grid-relation{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1.4rem;
  }
  .grid-relation-margin{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1.4rem;
  }

  .flag-date-button{
    display: block;
    justify-content: space-between;
    position: relative;
  }
  
  .flag-date-button button.detail{
    display: block;
    width: 84%;
  }

  .close-link{
    display:block;
    width: 84%;
  }

  .flagBox{
    width: 44%;
    display: inline-block;
    padding: 18px;
    height: 250px;
    vertical-align: middle;
    margin: 20px 3%;
    cursor: pointer;
  }

  /*modal*/

  .modal-inner{
    top: 5%;
    width: 80%;
    max-height: 80vh;
  }
  .modal-column{
    display: block;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0;
  }

  /*国旗表示エリア*/
  .modal-flag{
    width: 100%;
    padding-bottom: 18px;
  }

  /*国旗画像自体*/
  .modal-info{
    width: 100%;
  }

  .modal-button{
    display: block;
  }
  .modal-button button{
    display: block;
    width: 90%;
    margin: 24px auto;
    padding: 0 6px;
  }

  footer .footer-list, footer .logoFooter{
    float: none;
    margin-left: 3%;
  }

}
@media screen and (max-width: 480px){

  .flag-page{
    padding: 48px 8px;
  }

  .flag-date-column{
    display:block;
  }

  .flag-date-image{
    width: 100%;
    margin-bottom: 18px;
  }

  .flag-date-image-svg{
    max-width: 80%;
    margin: 0 auto;
  }

  .flag-date-location{
    width: 100%;
    margin-bottom: 18px;
  }

  .flag-date-map-image{
    max-width: 80%;
    margin: 0 auto;
  }

  .relation-flag{
    cursor: pointer;
  }
  .grid-relation{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1.4rem;
  }
  .grid-relation-margin{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1.4rem;
  }

  .flagArea{
    padding: 24px 0;
    margin: 0 auto;
    max-width: 90%;
  }

  .flagBox{
    width: 48%;
    max-width: 250px;
    padding: 18px;
    height: 160px;
    cursor: pointer;
  }

  a:nth-child(odd) .flagBox{
    margin:30px 2% 0 0;
  }

  a:nth-child(even) .flagBox{
    margin:30px 0 0 2%;
  }

  a:nth-child(1) .flagBox, a:nth-child(2) .flagBox{
    margin-top: 0;
  }

  .countryName{
    font-size: 0.86em;
  }

  .attributeBox{
    display: none;
  }

  /*
  Headerエリア
  */

  header{
    width: 100%;
    height: 70px;
    padding: 8px 12px;
  }

  header nav{
    float: left;
    line-height: 54px;
    padding: 0 12px;
  }

  header nav a{
    padding: 0 12px;
    color: #2b2b2b;
    text-decoration: none;
  }

  .logoHeader{
    width: 90px;
    height: 50px;
    float: left;
  }

  /*flagsエリア*/

  .howto{
    max-width: 90%;
  }

  .searchBox{
    max-width: 100%;
    margin:0 auto;
    margin-top: 36px;
    margin-bottom: 12px;
  }

  /*modal*/

  .modal-inner{
    top: 5%;
    max-height: 80vh;
  }
  .modal-column{
    display: block;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0;
  }

  /*国旗表示エリア*/
  .modal-flag{
    width: 100%;
    padding-bottom: 18px;
  }

  /*国旗画像自体*/
  .modal-flag-image{
    display: block;
    max-width: 80%;
    max-height: 300px;
    margin: 0 auto;
  }
  .modal-info{
    width: 100%;
  }

  .modal-button{
    display: block;
  }
  .modal-button button{
    display: block;
    width: 90%;
    margin: 24px auto;
    padding: 0 6px;
  }

  .modal-button button.detail{
    font-size:0.65em;
  }

  footer .footer-list, footer .logoFooter{
    margin-left: 10%;
  }

}
/*セントクリストファーネイビス用*/
@media screen and (max-width: 360px){
  .saintChristopherNevisBox .modal-button button.detail{
    height: 48px;
    line-height: 24px;
  }

  .saintChristopherNevisBox .countryName{
    font-size: 0.8em;
  }
}

/*
アンティグア・バーブーダ
ボスニア・ヘルツェゴビナ
*/
@media screen and (max-width: 310px){
  .antiguaBarbudaBox .modal-button button.detail,
  .bosniaHerzegovinaBox .modal-button button.detail{
    height: 48px;
    line-height: 24px;
  }
}

/*
サントメ・プリンシペ
トリニダード・トバゴ

  .saotomePrincipeBox .modal-button button.detail,
  .trinidadTobagoBox .modal-button button.detail{
    height: 48px;
    line-height: 24px;
  }

  いらなくなった
*/
@media screen and (max-width: 320px){

  header nav a{
    font-size: 0.75em;
  }
}